import React from "react";
import Calendar from "../../assets/icons/Calendar.png";
import "./_leaves.scss";
import DatePicker from "../../components/Form/DatePicker";
import Textarea from "../../components/Form/Textarea";
const NegotiationDetails = ({ formik, disabled }) => {
  return (
    <>
      <DatePicker
        calendarPopperPosition="bottom"
        formik={formik}
        type="date"
        label="Leave Date"
        name="negotiationDate"
        withIcon
        rightIcon={Calendar}
        className={
          disabled ? "diableddescription negotiation-date" : "negotiation-date"
        }
        error={
          formik.touched?.negotiationDate?.from === true &&
          formik.values.negotiationDate.to === null
            ? "Date is required!"
            : null
        }
      />

      <Textarea
        className={disabled ? "diableddescription" : ""}
        type="textarea"
        label="Description"
        name="negotiationDescription"
      />
    </>
  );
};

export default NegotiationDetails;
