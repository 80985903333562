import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import ApplyForNegotiationForm from "./ApplyForNegotiationForm";

const ApplyForNegotiationModal = ({
  title,
  className,
  id,
  open,
  handleClose,
  data,
  isEmployee,
  ...rest
}) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason == "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal "
    >
      <DialogTitle id="alert-dialog-title" className="modal_header">
        <span className={className}>
          {data?.row ? title : "Negotiation details "}
        </span>
        <div className="close_button">
          <CloseIcon onClick={() => handleClose(id)} />
        </div>
      </DialogTitle>
      <DialogContent>
        <ApplyForNegotiationForm
          handleClose={handleClose}
          id={id}
          data={data}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ApplyForNegotiationModal;
